<template lang="">
  <div>
    <b-container
      name="related"
      v-if="filteredProjects().length > 0"
      class="mb-4"
    >
      <b-row>
        <b-col>
          <h3>Related Projects</h3>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col
          v-for="(project, index) in filteredProjects()"
          :key="index"
          sm="6"
          md="4"
          lg="3"
          class="rounded-lg related-card mx-3 my-2"
          @click="load_project(project)"
        >
          <b-row>
            <b-col
              class="related-image"
              :style="
                'background: url(\'../' +
                loadProjectImg(project.coverImage) +
                '\') no-repeat center center / cover; background-size:cover;'
              "
            >
            </b-col>
          </b-row>
          <b-row>
            <b-col class="my-2"> {{ project.title }} </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
const images = require.context(
  "../assets/project_images",
  true,
  /\.png$|\.jpg$/
);

export default {
  name: "RelatedProjects",
  props: ["relatedProjectsList"],
  mounted() {},
  watch: {},
  methods: {
    filteredProjects() {
      if (this.relatedProjectsList == null) return [];
      let res = [];
      let res_tmp = [];
      for (const ptype of Object.keys(this.$store.state.workTypes)) {
        res_tmp = this.$store.state.workTypes[ptype].data.filter((project) => {
          project["ptype"] = ptype;
          return this.relatedProjectsList.includes(project.name);
        });
        res = res.concat(res_tmp);
      }
      return res;
    },
    loadProjectImg(imgPath) {
      return images("./" + imgPath);
    },

    load_project(project) {
      this.$router.push({
        path: "../" + project.ptype + "/" + project.name,
      });
    },
  },
};
</script>
<style>
.related-card {
  padding: 1rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
  background: rgba(163, 163, 177, 0.404);
  border: solid 1px rgba(255, 255, 255, 0.308);
}
.related-image {
  display: flex;
  justify-content: center;
  background-size: cover;
  height: 100%;
  align-content: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 10rem;
}
</style>
