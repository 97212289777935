<template lang="">
  <div class="page-container">
    <article class="intro-article">
      <section class="intro">
        <div class="container">
          <b-row>
            <b-col>
              <h1 class="pt-1">
                {{ project.title }}
              </h1>
              <div class="company-date mb-2">
                <div v-if="project.company != null" class="medium">
                  {{ project.company }}
                </div>
                <div
                  v-if="project.project_date != null && project.company != null"
                  class="mr-3 medium"
                ></div>
                <div v-if="project.project_date != null" class="medium">
                  ({{ project.project_date }})
                </div>
              </div>
              <b-img
                style="width: 85%"
                :src="'../' + loadProjectImg(project.coverImage)"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="p-3">
              <p class="lead">
                {{ project.description }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="features">
              <ul class="list-inline">
                <li
                  class="list-inline-item rounded"
                  v-for="(feature, index) in project.features"
                  :key="index"
                >
                  {{ feature }}
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </section>
    </article>

    <article
      class="section-block"
      v-for="(data, index) in project.sections"
      :key="index"
    >
      <!-- :style="
            'background: url(\'../' +
            loadProjectImg(data.image) +
            '\') no-repeat center center;background-size: ' +
            coverContain(data.imagecover) +
            '\';background-position: top;'
          " -->
      <section class="page-sections d-flex">
        <b-container fluid="sm">
          <b-row>
            <b-col :order-md="getOrder(index)" cols="12" md="6">
              <div class="image-container">
                <b-img
                  fluid
                  class="section-image"
                  :src="'../' + loadProjectImg(data.image)"
                />
                <!-- <b-img class="section-image" :src="loadProjectImg(data.image)" /> -->
              </div>
            </b-col>
            <b-col order-md="1" cols="12" md="6">
              <div class="section-text">
                <div v-if="data.header != null" class="section-head-text">
                  {{ data.header }}
                </div>
                <div><span v-html="data.text"></span></div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
    </article>
    <article class="section-block" v-if="project.gallery != null">
      <section class="page-sections-vertical">
        <div>
          <h3>Photo Gallery</h3>
        </div>
        <b-container class="page-sections">
          <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="4000"
            controls
            indicators
            background="#ababab"
            img-width="1000"
            img-height="750"
          >
            <!-- Text slides with image -->
            <b-carousel-slide
              v-for="(image, index) in project.gallery"
              :key="index"
              :text="image.caption"
              :img-src="'../' + loadProjectImg(image.src)"
            />
          </b-carousel>
        </b-container>
      </section>
    </article>
    <article class="section-block" v-if="project.video != null">
      <section class="video-section">
        <b-container>
          <b-row>
            <b-col>
              <h4>{{ project.video.title }}</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <LazyYoutube :src="project.video.src" />
            </b-col>
          </b-row>
        </b-container>
      </section>
    </article>

    <article class="end-section">
      <section>
        <RelatedProjects :related-projects-list="project.related_projects" />
        <b-button @click="$router.push('/')"> Return home </b-button>
      </section>
    </article>
  </div>
</template>

<script>
// import projectsData from "../projects.json";
import RelatedProjects from "../components/RelatedProjects.vue";
const images = require.context(
  "../assets/project_images",
  true,
  /\.png$|\.jpg$|\.gif$/
);

export default {
  name: "Project",
  data() {
    return {
      project_name: "",
      projects: [],
      id: 0,
      project: [],
      slide: 0,
      sliding: null,
    };
  },
  components: { RelatedProjects },
  watch: {},
  created() {
    let ptype = this.$route.path.split("/")[1];
    this.projects = this.$store.state.workTypes[ptype].data;
    this.getProjectIndex(this.$route.params.project_name);
    this.updatePageTitle(this.project.title);
  },
  methods: {
    getProjectIndex(name) {
      for (var i = 0; i < this.projects.length; i++) {
        if (this.projects[i].name === name) {
          this.project = this.projects[i];
        }
      }
      return -1;
    },
    // updatePageTitle() {
    //   document.title = process.env.VUE_APP_TITLE + " | " + this.project.title;
    // },
    loadProjectImg(imgPath) {
      return images("./" + imgPath);
    },
    coverContain(isCovered) {
      if (isCovered == null) return "contain;";
      return isCovered ? "cover;" : "contain;";
    },
    getOrder(index) {
      if (index % 2 == 0) return 1;
      else return 2;
    },
  },
};
</script>
<style lang="scss">
$section-background-color: #2e364d;
.page-container {
  justify-content: center;
  text-align: center;
  //background: url("./../assets/headerbackground4.jpg") no-repeat top center;
  background: #02011a;
  background-size: cover;
}
.company-date {
  display: flex;
  justify-content: center;
}
.intro-article {
  display: flex;
  justify-content: center;
  width: 100%;
}
.intro {
  background: linear-gradient(
    to top,
    $section-background-color,
    $section-background-color,
    #02011a
  );
  color: #e2e2e2;
  max-width: 1200px;
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
  p {
    font-size: 1.125rem;
  }
  .row {
    text-align: center;
    display: flex;
    justify-items: center;
    justify-content: center;
  }
  .intro-header {
    min-height: 25vh;

    max-width: 55%;
    color: rgb(255, 255, 255);
    text-shadow: 2px 2px #0000008a;
  }
}

.features {
  text-align: center;

  .list-inline-item {
    background: rgba(194, 194, 194, 0.164);
    font-size: 0.8rem;
    padding: 0.15rem;
    margin: 0.25rem;
    border: 1px solid #5c5c5c;
  }
}

.page-sections {
  display: flex;
  justify-content: center;
  max-width: 1200px;
  min-height: 30vh;
  background: $section-background-color;
  width: 100%;
  padding-bottom: 1rem;
}
.page-sections-vertical {
  justify-content: column;
  max-width: 1200px;
  min-height: 30vh;
  background: $section-background-color;
  width: 100%;
}
.left-align {
  display: flex;
  justify-content: left;
  background: $section-background-color;
  color: white;
  // background: linear-gradient(
  //   to bottom,
  //   #474747,
  //   #47474741,
  //   #02011a5e,
  //   #4747474f,
  //   #474747
  // );
}
.right-align {
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
  justify-items: right;
  background: $section-background-color;
  color: rgb(255, 255, 255);
  // background: linear-gradient(
  //   to bottom,
  //   #474747,
  //   #4747474f,
  //   #02011aab,
  //   #4747474f,
  //   #474747
  // );
}
.image-container {
  display: block;
  overflow: auto;

  // padding: 2rem;
  .section-image {
    border-radius: 1.25rem;
  }
}

.section-block {
  display: flex;
  justify-content: center;
  width: 100%;
}
.video-section {
  max-width: 1200px;
  width: 100%;
  background: $section-background-color;
  padding-bottom: 2rem;
  padding-top: 2rem;
}
.end-section {
  //background: $section-background-color;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.section-head-text {
  font-weight: 700;
  text-decoration: underline;
  padding-bottom: 1rem;
}
.section-text {
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
  overflow-wrap: break-word;
  font-size: 1rem;
  font-weight: 300;
}
.carousel {
  max-width: 1000px;
  box-shadow: 0px 0px 25px rgba(54, 54, 54, 0.726);
  border: 2px solid rgb(39, 40, 44);
  p {
    color: #fff;
  }
}

@media screen and (max-width: 400px) {
  .section-text {
    //padding: 0.5rem;
    font-size: 0.9rem;
  }
  .image-container {
    // padding: 0.5rem;
    .section-image {
      border-radius: 0.5rem;
    }
  }
}
@media screen and (min-width: 900px) {
  .section-text {
    // padding: 2rem;
    font-size: 1.125rem;
  }
  .image-container {
    // padding: 2rem;
    .section-image {
      border-radius: 1.25rem;
    }
  }
}
</style>
