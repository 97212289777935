import { render, staticRenderFns } from "./RelatedProjects.vue?vue&type=template&id=c2c87818&lang=true&"
import script from "./RelatedProjects.vue?vue&type=script&lang=js&"
export * from "./RelatedProjects.vue?vue&type=script&lang=js&"
import style0 from "./RelatedProjects.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports